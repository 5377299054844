import package_data from "../package.json";

const title = "TeamConnect";

var config = {
  title,
  version: package_data.version,
  development: process.env.NODE_ENV === "development",
  production: process.env.NODE_ENV === "production",
  api: process.env.REACT_APP_API || "https://teamconnect-zzp-8f979bba9aac.herokuapp.com/"
};

export default config;
